import type { AnalyticsPayloadAction, AnalyticsOptions, AnalyticsPayload } from "@/types";
import { useState, useGtag } from "vue-gtag-next";
import { Base64 } from "js-base64";

const analyticsOptions = ref<AnalyticsOptions>({
  apiUrl: "",
  appId: "",
  userId: "",
  tenant: "",
  useZenAnalytics: false,
  useGtag: false,
  gtag: {
    enabled: false,
    config: undefined,
  },
  appReleaseNumber: "",
  useageType: "device",
  eventId: "",
  useZenClientHeader: false,
});

const isConsent = ref(false);
const { isEnabled } = useState();

watch(isConsent, () => {
  if (
    isConsent.value &&
    analyticsOptions.value.useGtag &&
    useRuntimeConfig().public.nodeEnv === "production"
  ) {
    initGtag();
  }
});

const initGtag = () => {
  const { property } = useState();

  property!.value = useRuntimeConfig()
    .public.gaTagIds.split(",")
    .map((id) => {
      return {
        id,
      };
    });

  isEnabled!.value = isConsent.value;
};

const sendScreenView = (
  analyticsObject: AnalyticsPayloadAction,
  ignoreGtag = false,
  appId?: string
): void => {
  if (isConsent.value) {
    if (
      !ignoreGtag &&
      analyticsOptions.value.useGtag &&
      useRuntimeConfig().public.nodeEnv === "production"
    ) {
      const { screenview } = useGtag();
      screenview({
        app_name: analyticsOptions.value.appId,
        screen_name: analyticsObject.target,
      });
    }
  }

  if (analyticsOptions.value.useZenAnalytics && !import.meta.server) {
    sendZenAnalytics(analyticsObject, appId);
  }
};

const sendZenAnalytics = async (evt: AnalyticsPayloadAction, appId: string) => {
  const { getGuid, getSessionId } = useUtils();
  const route = useRoute();
  const eventPayload: AnalyticsPayload[] = [
    {
      subject: {
        type: analyticsOptions.value.useageType || "",
        deviceId: getGuid() || "",
        userAgent: window.navigator.userAgent,
        sessionId: getSessionId(),
        appReleaseNumber: analyticsOptions.value.appReleaseNumber || "",
        userId: analyticsOptions.value.userId,
        appId: appId || analyticsOptions.value.appId,
      },
      action: {
        type: evt.type,
        location: evt.location || "",
      },
      object: {
        target: evt.target,
      },
      eventTime: new Date().toISOString(),
      tenant: analyticsOptions.value.tenant,
    },
  ];

  const post = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Zen-Client": Base64.encode(JSON.stringify(eventPayload[0].subject)),
    },
  };

  const payload = { ...post, body: JSON.stringify(eventPayload) };

  const resp = await fetch(analyticsOptions.value.apiUrl, payload);
  const json = await resp.json();
  return json;
};

export const useAnalytics = (options: AnalyticsOptions | null = null) => {
  if (options) {
    analyticsOptions.value = options;
  }

  return {
    isConsent,
    analyticsOptions,
    sendScreenView,
  };
};
