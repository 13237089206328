<script setup lang="ts">
import { useRoute } from "vue-router";
import { useScriptTag } from "@vueuse/core";

const route = useRoute();

const { getCdnUrl } = useEnvPaths();

const appVersion = await useAppVersion();

const nuxtApp = useNuxtApp();
nuxtApp.hook("page:finish", () => {
  window.scrollTo(0, 0);
});

const { load } = useScriptTag(`${getCdnUrl()}/assets/js/feeder.js`, () => {}, {
  manual: true,
});

const { load: loadConfig } = useScriptTag(`${getCdnUrl()}/assets/js/feeder-config.js`, () => {}, {
  manual: true,
});

const { appName } = useEnvPaths();
const { isConsent, sendScreenView } = useAnalytics();
const consent = useCookie(`${appName()}_cookieConsent`);
const appMounted = useState("appMounted", () => false);

onMounted(async () => {
  const { getAnalyticsUrl } = useEnvPaths();
  const { getSessionId } = useUtils();
  const analyticsOptions = {
    apiUrl: `${getAnalyticsUrl() as string}`,
    appId: "ots",
    userId: getSessionId(),
    tenant: "ots",
    appName: appName() as string,
    useZenAnalytics: true,
    useGtag: useRuntimeConfig().public.analyticsEnabled,
    gtag: {
      enabled: useRuntimeConfig().public.nodeEnv === "production",
      config: {
        id: useRuntimeConfig().public.gaTagIds,
      },
    },
    appReleaseNumber: appVersion.version,
    useageType: "user",
    eventId: "",
    useZenClientHeader: false,
  };

  useAnalytics(analyticsOptions);

  isConsent.value = !!consent.value;

  if (isConsent.value) {
    await loadConfig();
    load();
  }

  appMounted.value = true;
});

async function onCookieConsentAccepted() {
  isConsent.value = true;
  consent.value = "true";
  sendScreenView({ type: "click", target: "gdpr-accepted" });
  await loadConfig();
  load();
}

onUnmounted(() => {
  const { sendScreenView } = useAnalytics();
  sendScreenView({
    type: "UnloadEvent",
    target: "index",
  });
});
</script>

<template>
  <div class="app-wrapper">
    <TheNavBar v-if="route.path !== '/creative-services'" />
    <NuxtPage />
    <TheFooter />
    <Cookies v-if="!isConsent" @close-modal="onCookieConsentAccepted" />
  </div>
</template>
