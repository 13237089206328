<script setup lang="ts">
const scrollingToTarget = useScrollingToTarget();
let scrollTimeout: ReturnType<typeof setTimeout>;

function onItemClick(): void {
  scrollingToTarget.value = true;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    scrollingToTarget.value = false;
  }, 2000);
}
</script>

<template>
  <footer>
    <div class="content">
      <ul>
        <li>
          <NuxtLink
            to="/#feature"
            class="footer-logo"
            aria-label="Go to top of home page"
            @click="onItemClick"
          >
            <img src="/assets/logos/zen-2023-header.svg" alt="Zenapptic.ai" />
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/privacy">PRIVACY</NuxtLink> &nbsp; | &nbsp;
          <NuxtLink href="/terms">TERMS OF SERVICE</NuxtLink>
        </li>
        <li class="gray">
          Copyright {{ new Date().getFullYear() }}. Zenapptic AI Inc. All rights
          reserved.
        </li>
      </ul>

      <ul class="social-links">
        <li>
          <NuxtLink
            to="https://vimeo.com/zenapptic"
            class="zen-link vimeo"
            target="_blank"
          >
            <span class="sr-only">Zennaptic on Vimeo</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="https://www.linkedin.com/company/zenapptic-ai/"
            class="zen-link linkedin"
            target="_blank"
          >
            <span class="sr-only">Zennaptic on LinkedIn</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="https://x.com/zenapptic"
            class="zen-link x"
            target="_blank"
          >
            <span class="sr-only">Zennaptic on X</span>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss">
footer {
  background: black;
  padding: 3rem 3rem;

  .content {
    align-items: center;
    color: #fff;
    display: grid;
    font-size: min(3vw, 1.4rem);
    grid-template-columns: 1fr auto;
    margin: auto;
    max-width: 1366px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0.6rem 0.3rem;

        &.gray {
          color: #9f9f9f;
        }

        a {
          color: white;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .footer-logo {
          img {
            height: 45px;
            margin-left: -5px;
          }
        }

        .zen-link {
          background: no-repeat left center;
          background-size: auto 90%;
          color: #f0f0e6;
          padding-left: 25px;

          &.linkedin {
            background-image: url(/assets/icons/linkedin-icon.png);
          }

          &.vimeo {
            background-image: url(/assets/icons/vimeo-icon.png);
          }

          &.x {
            background-image: url(/assets/icons/x-icon.png);
          }
        }
      }

      &.social-links {
        display: flex;
        gap: 2rem;
      }
    }

    .footer-logo {
      width: 54px;
    }

    @media only screen and (max-device-width: 600px) {
      .footer-logo {
        width: 40px;
      }
    }
  }
}
</style>
